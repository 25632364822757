


import MenuEn from './en/MenuEn';
import MenuEs from './es/MenuEs';
import MenuRu from './ru/MenuRu';
import HomeEn from './en/HomeEn';
import HomeEs from './es/HomeEs';
import HomeRu from './ru/HomeRu';
import AboutEn from './en/AboutEn';
import AboutEs from './es/AboutEs';
import AboutRu from './ru/AboutRu';
import ContactEn from './en/ContactEn';
import ContactEs from './es/ContactEs';
import ContactRu from './ru/ContactRu';
import SupportEn from './en/SupportEn';
import SupportEs from './es/SupportEs';
import SupportRu from './ru/SupportRu';

import PruebaEs from './es/aPruebaEs';
import BlogDetailsEs from './es/BlogDetailsEs';
import CategoryEs from './es/CategoryEs';

import EventsCultureEn from './en/EventsCultureEn';
import EventsCultureEs from './es/EventsCultureEs';
import EventsCultureRu from './ru/EventsCultureRu';
import EventsDanceEn from './en/EventsDanceEn';
import EventsDanceEs from './es/EventsDanceEs';
import EventsDanceRu from './ru/EventsDanceRu';
import EventSpanishEn from './en/EventSpanishEn';
import EventSpanishEs from './es/EventSpanishEs';
import EventSpanishRu from './ru/EventSpanishRu';
import GalleryEn from './en/GalleryEn';
import GalleryEs from './es/GalleryEs';
import GalleryRu from './ru/GalleryRu';
import PhotosEn from './en/PhotosEn';
import PhotosEs from './es/PhotosEs';
import PhotosRu from './ru/PhotosRu';
import ProjectsEn from './en/ProjectsEn';
import ProjectsEs from './es/ProjectsEs';
import ProjectsRu from './ru/ProjectsRu';

import RestaHomeEn from './en/RestaHomeEn';
import RestaHomeEs from './es/RestaHomeEs';
import RestaHomeRu from './ru/RestaHomeRu';

import SpanishLanguageEn from './en/WebuniEn';
import SpanishLanguageEs from './es/WebuniEs';
import SpanishLanguageRu from './ru/WebuniRu';
import SchoolsDanceEn from './en/SchoolsDanceEn';
import SchoolsDanceEs from './es/SchoolsDanceEs';
import SchoolsDanceRu from './ru/SchoolsDanceRu';
import SchoolsSpanishEn from './en/SchoolsSpanishEn';
import SchoolsSpanishEs from './es/SchoolsSpanishEs';
import SchoolsSpanishRu from './ru/SchoolsSpanishRu';
import StoreEn from './en/StoreEn';
import StoreEs from './es/StoreEs';
import StoreRu from './ru/StoreRu';
import TeachersDanceEn from './en/TeachersDanceEn';
import TeachersDanceEs from './es/TeachersDanceEs';
import TeachersDanceRu from './ru/TeachersDanceRu';
import TeacherSpanishEn from './en/TeacherSpanishEn';
import TeacherSpanishEs from './es/TeacherSpanishEs';
import TeacherSpanishRu from './ru/TeacherSpanishRu';
import VideoEventsEn from './en/VideoEventsEn';
import VideoEventsEs from './es/VideoEventsEs';
import VideoEventsRu from './ru/VideoEventsRu';


import TurizoLaBachataEs from '../pages/es/lyrics/TurizoLaBachataEs';
import TurizoLaBachataRu from '../pages/ru/lyrics/TurizoLaBachataRu';
import LatinoDanceMoscowEn from '../pages/en/lifestyle/231228En-latino-dance-in-Moscow';
import LatinoDanceMoscowEs from '../pages/es/lifestyle/231228Es-latino-dance-in-Moscow';
import LatinoDanceMoscowRu from '../pages/ru/lifestyle/231228Ru-latino-dance-in-Moscow';
import TravelToSpainEs from '../pages/es/travel/231228Es-travel-spain';
import TravelToSpainRu from '../pages/ru/travel/231228Ru-travel-spain';
import NewYearLatamEs from '../pages/es/society/231228Es-new-year-latam';
import NewYearLatamRu from '../pages/ru/society/231228Ru-new-year-latam';
import OpenAirMoscowEn from '../pages/en/lifestyle/240506En-open-air-in-Moscow';
import OpenAirMoscowEs from '../pages/es/lifestyle/240506Es-open-air-in-Moscow';
import OpenAirMoscowRu from '../pages/ru/lifestyle/240506Ru-open-air-in-Moscow';  
import LatinaFestMoscowEn from '../pages/en/lifestyle/240716En-latina-fest-Moscow';
import LatinaFestMoscowEs from '../pages/es/lifestyle/240716Es-latina-fest-Moscow';
import LatinaFestMoscowRu from '../pages/ru/lifestyle/240716Ru-latina-fest-Moscow';
import SalsatecaEn from '../pages/en/schdance/240611En-salsateca';
import SalsatecaEs from '../pages/es/schdance/240611Es-salsateca';
import SalsatecaRu from '../pages/ru/schdance/240611Ru-salsateca';

import Photo240509En from '../pages/en/photo/PhotoEn-240509';
import Photo240509Es from '../pages/es/photo/PhotoEs-240509';
import Photo240509Ru from '../pages/ru/photo/PhotoRu-240509';
import StoreAlpacaEn from '../pages/en/photo/PhotoGalleryAlpacaEn';
import StoreAlpacaEs from '../pages/es/photo/PhotoGalleryAlpacaEs';
import StoreAlpacaRu from '../pages/ru/photo/PhotoGalleryAlpacaRu';
import StorePeruEn from '../pages/en/photo/PhotoStorePeruEn';
import StorePeruEs from '../pages/es/photo/PhotoStorePeruEs';
import StorePeruRu from '../pages/ru/photo/PhotoStorePeruRu';

import VideoOpen240518En from '../pages/en/avideo/VideoEn-Open240518';
import VideoOpen240518Es from '../pages/es/avideo/VideoEs-Open240518';
import VideoOpen240518Ru from '../pages/ru/avideo/VideoRu-Open240518';
import VideoOpen240519En from '../pages/en/avideo/VideoEn-Open240519';
import VideoOpen240519Es from '../pages/es/avideo/VideoEs-Open240519';
import VideoOpen240519Ru from '../pages/ru/avideo/VideoRu-Open240519';
import VideoOpen240525En from '../pages/en/avideo/VideoEn-Open240525';
import VideoOpen240525Es from '../pages/es/avideo/VideoEs-Open240525';
import VideoOpen240525Ru from '../pages/ru/avideo/VideoRu-Open240525';
import VideoOpen240528En from '../pages/en/avideo/VideoEn-Open240528';
import VideoOpen240528Es from '../pages/es/avideo/VideoEs-Open240528';
import VideoOpen240528Ru from '../pages/ru/avideo/VideoRu-Open240528';
import VideoOpen240530En from '../pages/en/avideo/VideoEn-Open240530';
import VideoOpen240530Es from '../pages/es/avideo/VideoEs-Open240530';
import VideoOpen240530Ru from '../pages/ru/avideo/VideoRu-Open240530';
import VideoOpen240531En from '../pages/en/avideo/VideoEn-Open240531';
import VideoOpen240531Es from '../pages/es/avideo/VideoEs-Open240531';
import VideoOpen240531Ru from '../pages/ru/avideo/VideoRu-Open240531';
import VideoOpen240602En from '../pages/en/avideo/VideoEn-Open240602';
import VideoOpen240602Es from '../pages/es/avideo/VideoEs-Open240602';
import VideoOpen240602Ru from '../pages/ru/avideo/VideoRu-Open240602';
import VideoOpen240607En from '../pages/en/avideo/VideoEn-Open240607';
import VideoOpen240607Es from '../pages/es/avideo/VideoEs-Open240607';
import VideoOpen240607Ru from '../pages/ru/avideo/VideoRu-Open240607';
import VideoOpen240608En from '../pages/en/avideo/VideoEn-Open240608';
import VideoOpen240608Es from '../pages/es/avideo/VideoEs-Open240608';
import VideoOpen240608Ru from '../pages/ru/avideo/VideoRu-Open240608';
import VideoOpen240609En from '../pages/en/avideo/VideoEn-Open240609';
import VideoOpen240609Es from '../pages/es/avideo/VideoEs-Open240609';
import VideoOpen240609Ru from '../pages/ru/avideo/VideoRu-Open240609';
import VideoOpen240614En from '../pages/en/avideo/VideoEn-Open240614';
import VideoOpen240614Es from '../pages/es/avideo/VideoEs-Open240614';
import VideoOpen240614Ru from '../pages/ru/avideo/VideoRu-Open240614';
import VideoOpen240615En from '../pages/en/avideo/VideoEn-Open240615';
import VideoOpen240615Es from '../pages/es/avideo/VideoEs-Open240615';
import VideoOpen240615Ru from '../pages/ru/avideo/VideoRu-Open240615';
import VideoOpen240616En from '../pages/en/avideo/VideoEn-Open240616';
import VideoOpen240616Es from '../pages/es/avideo/VideoEs-Open240616';
import VideoOpen240616Ru from '../pages/ru/avideo/VideoRu-Open240616';
import VideoOpen240619En from '../pages/en/avideo/VideoEn-Open240619';
import VideoOpen240619Es from '../pages/es/avideo/VideoEs-Open240619';
import VideoOpen240619Ru from '../pages/ru/avideo/VideoRu-Open240619';
import VideoOpen240621En from '../pages/en/avideo/VideoEn-Open240621';
import VideoOpen240621Es from '../pages/es/avideo/VideoEs-Open240621';
import VideoOpen240621Ru from '../pages/ru/avideo/VideoRu-Open240621';
import VideoOpen240622En from '../pages/en/avideo/VideoEn-Open240622';
import VideoOpen240622Es from '../pages/es/avideo/VideoEs-Open240622';
import VideoOpen240622Ru from '../pages/ru/avideo/VideoRu-Open240622';
import VideoOpen240623En from '../pages/en/avideo/VideoEn-Open240623';
import VideoOpen240623Es from '../pages/es/avideo/VideoEs-Open240623';
import VideoOpen240623Ru from '../pages/ru/avideo/VideoRu-Open240623';
import VideoOpen240625En from '../pages/en/avideo/VideoEn-Open240625';
import VideoOpen240625Es from '../pages/es/avideo/VideoEs-Open240625';
import VideoOpen240625Ru from '../pages/ru/avideo/VideoRu-Open240625';
import VideoOpen240626En from '../pages/en/avideo/VideoEn-Open240626';
import VideoOpen240626Es from '../pages/es/avideo/VideoEs-Open240626';
import VideoOpen240626Ru from '../pages/ru/avideo/VideoRu-Open240626';
import VideoOpen240628En from '../pages/en/avideo/VideoEn-Open240628';
import VideoOpen240628Es from '../pages/es/avideo/VideoEs-Open240628';
import VideoOpen240628Ru from '../pages/ru/avideo/VideoRu-Open240628';
import VideoOpen240629En from '../pages/en/avideo/VideoEn-Open240629';
import VideoOpen240629Es from '../pages/es/avideo/VideoEs-Open240629';
import VideoOpen240629Ru from '../pages/ru/avideo/VideoRu-Open240629';
import VideoOpen240630En from '../pages/en/avideo/VideoEn-Open240630';
import VideoOpen240630Es from '../pages/es/avideo/VideoEs-Open240630';
import VideoOpen240630Ru from '../pages/ru/avideo/VideoRu-Open240630';
import VideoOpen240701En from '../pages/en/avideo/VideoEn-Open240701';
import VideoOpen240701Es from '../pages/es/avideo/VideoEs-Open240701';
import VideoOpen240701Ru from '../pages/ru/avideo/VideoRu-Open240701';
import VideoOpen240702En from '../pages/en/avideo/VideoEn-Open240702';
import VideoOpen240702Es from '../pages/es/avideo/VideoEs-Open240702';
import VideoOpen240702Ru from '../pages/ru/avideo/VideoRu-Open240702';
import VideoOpen240703En from '../pages/en/avideo/VideoEn-Open240703';
import VideoOpen240703Es from '../pages/es/avideo/VideoEs-Open240703';
import VideoOpen240703Ru from '../pages/ru/avideo/VideoRu-Open240703';
import VideoOpen240706En from '../pages/en/avideo/VideoEn-Open240706';
import VideoOpen240706Es from '../pages/es/avideo/VideoEs-Open240706';
import VideoOpen240706Ru from '../pages/ru/avideo/VideoRu-Open240706';
import VideoOpen240707En from '../pages/en/avideo/VideoEn-Open240707';
import VideoOpen240707Es from '../pages/es/avideo/VideoEs-Open240707';
import VideoOpen240707Ru from '../pages/ru/avideo/VideoRu-Open240707';
import VideoOpen240709En from '../pages/en/avideo/VideoEn-Open240709';
import VideoOpen240709Es from '../pages/es/avideo/VideoEs-Open240709';
import VideoOpen240709Ru from '../pages/ru/avideo/VideoRu-Open240709';
import VideoOpen240710En from '../pages/en/avideo/VideoEn-Open240710';
import VideoOpen240710Es from '../pages/es/avideo/VideoEs-Open240710';
import VideoOpen240710Ru from '../pages/ru/avideo/VideoRu-Open240710';
import VideoOpen240712En from '../pages/en/avideo/VideoEn-Open240712';
import VideoOpen240712Es from '../pages/es/avideo/VideoEs-Open240712';
import VideoOpen240712Ru from '../pages/ru/avideo/VideoRu-Open240712';
import VideoOpen240713En from '../pages/en/avideo/VideoEn-Open240713';
import VideoOpen240713Es from '../pages/es/avideo/VideoEs-Open240713';
import VideoOpen240713Ru from '../pages/ru/avideo/VideoRu-Open240713';
import VideoOpen240714En from '../pages/en/avideo/VideoEn-Open240714';
import VideoOpen240714Es from '../pages/es/avideo/VideoEs-Open240714';
import VideoOpen240714Ru from '../pages/ru/avideo/VideoRu-Open240714';
import VideoOpen240716En from '../pages/en/avideo/VideoEn-Open240716';
import VideoOpen240716Es from '../pages/es/avideo/VideoEs-Open240716';
import VideoOpen240716Ru from '../pages/ru/avideo/VideoRu-Open240716';
import VideoOpen240717En from '../pages/en/avideo/VideoEn-Open240717';
import VideoOpen240717Es from '../pages/es/avideo/VideoEs-Open240717';
import VideoOpen240717Ru from '../pages/ru/avideo/VideoRu-Open240717'; 
import VideoOpen240718En from '../pages/en/avideo/VideoEn-Open240718';
import VideoOpen240718Es from '../pages/es/avideo/VideoEs-Open240718';
import VideoOpen240718Ru from '../pages/ru/avideo/VideoRu-Open240718';   
import VideoOpen240719En from '../pages/en/avideo/VideoEn-Open240719';
import VideoOpen240719Es from '../pages/es/avideo/VideoEs-Open240719';
import VideoOpen240719Ru from '../pages/ru/avideo/VideoRu-Open240719'; 
import VideoOpen240720En from '../pages/en/avideo/VideoEn-Open240720';
import VideoOpen240720Es from '../pages/es/avideo/VideoEs-Open240720';
import VideoOpen240720Ru from '../pages/ru/avideo/VideoRu-Open240720'; 
import VideoOpen240723En from '../pages/en/avideo/VideoEn-Open240723';
import VideoOpen240723Es from '../pages/es/avideo/VideoEs-Open240723';
import VideoOpen240723Ru from '../pages/ru/avideo/VideoRu-Open240723';
import VideoOpen240725En from '../pages/en/avideo/VideoEn-Open240725';
import VideoOpen240725Es from '../pages/es/avideo/VideoEs-Open240725';
import VideoOpen240725Ru from '../pages/ru/avideo/VideoRu-Open240725';
import VideoOpen240731En from '../pages/en/avideo/VideoEn-Open240731';
import VideoOpen240731Es from '../pages/es/avideo/VideoEs-Open240731';
import VideoOpen240731Ru from '../pages/ru/avideo/VideoRu-Open240731';
import VideoOpen240801En from '../pages/en/avideo/VideoEn-Open240801';
import VideoOpen240801Es from '../pages/es/avideo/VideoEs-Open240801';
import VideoOpen240801Ru from '../pages/ru/avideo/VideoRu-Open240801';
import VideoOpen240802En from '../pages/en/avideo/VideoEn-Open240802';
import VideoOpen240802Es from '../pages/es/avideo/VideoEs-Open240802';
import VideoOpen240802Ru from '../pages/ru/avideo/VideoRu-Open240802';
import VideoOpen240803En from '../pages/en/avideo/VideoEn-Open240803';
import VideoOpen240803Es from '../pages/es/avideo/VideoEs-Open240803';
import VideoOpen240803Ru from '../pages/ru/avideo/VideoRu-Open240803';
import VideoOpen240804En from '../pages/en/avideo/VideoEn-Open240804';
import VideoOpen240804Es from '../pages/es/avideo/VideoEs-Open240804';
import VideoOpen240804Ru from '../pages/ru/avideo/VideoRu-Open240804';
import VideoOpen240806En from '../pages/en/avideo/VideoEn-Open240806';
import VideoOpen240806Es from '../pages/es/avideo/VideoEs-Open240806';
import VideoOpen240806Ru from '../pages/ru/avideo/VideoRu-Open240806';
import VideoOpen240807En from '../pages/en/avideo/VideoEn-Open240807';
import VideoOpen240807Es from '../pages/es/avideo/VideoEs-Open240807';
import VideoOpen240807Ru from '../pages/ru/avideo/VideoRu-Open240807';
import VideoOpen240808En from '../pages/en/avideo/VideoEn-Open240808';
import VideoOpen240808Es from '../pages/es/avideo/VideoEs-Open240808';
import VideoOpen240808Ru from '../pages/ru/avideo/VideoRu-Open240808';
import VideoOpen240809En from '../pages/en/avideo/VideoEn-Open240809';
import VideoOpen240809Es from '../pages/es/avideo/VideoEs-Open240809';
import VideoOpen240809Ru from '../pages/ru/avideo/VideoRu-Open240809';
import VideoOpen240810En from '../pages/en/avideo/VideoEn-Open240810';
import VideoOpen240810Es from '../pages/es/avideo/VideoEs-Open240810';
import VideoOpen240810Ru from '../pages/ru/avideo/VideoRu-Open240810';
import VideoOpen240813En from '../pages/en/avideo/VideoEn-Open240813';
import VideoOpen240813Es from '../pages/es/avideo/VideoEs-Open240813';
import VideoOpen240813Ru from '../pages/ru/avideo/VideoRu-Open240813';
import VideoOpen240814En from '../pages/en/avideo/VideoEn-Open240814';
import VideoOpen240814Es from '../pages/es/avideo/VideoEs-Open240814';
import VideoOpen240814Ru from '../pages/ru/avideo/VideoRu-Open240814';
import VideoOpen240815En from '../pages/en/avideo/VideoEn-Open240815';
import VideoOpen240815Es from '../pages/es/avideo/VideoEs-Open240815';
import VideoOpen240815Ru from '../pages/ru/avideo/VideoRu-Open240815';
import VideoOpen240816En from '../pages/en/avideo/VideoEn-Open240816';
import VideoOpen240816Es from '../pages/es/avideo/VideoEs-Open240816';
import VideoOpen240816Ru from '../pages/ru/avideo/VideoRu-Open240816';
import VideoOpen240820En from '../pages/en/avideo/VideoEn-Open240820';
import VideoOpen240820Es from '../pages/es/avideo/VideoEs-Open240820';
import VideoOpen240820Ru from '../pages/ru/avideo/VideoRu-Open240820';
import VideoOpen240821En from '../pages/en/avideo/VideoEn-Open240821';
import VideoOpen240821Es from '../pages/es/avideo/VideoEs-Open240821';
import VideoOpen240821Ru from '../pages/ru/avideo/VideoRu-Open240821';
import VideoOpen240822En from '../pages/en/avideo/VideoEn-Open240822';
import VideoOpen240822Es from '../pages/es/avideo/VideoEs-Open240822';
import VideoOpen240822Ru from '../pages/ru/avideo/VideoRu-Open240822';
import VideoOpen240823En from '../pages/en/avideo/VideoEn-Open240823';
import VideoOpen240823Es from '../pages/es/avideo/VideoEs-Open240823';
import VideoOpen240823Ru from '../pages/ru/avideo/VideoRu-Open240823';
import VideoOpen240824En from '../pages/en/avideo/VideoEn-Open240824';
import VideoOpen240824Es from '../pages/es/avideo/VideoEs-Open240824';
import VideoOpen240824Ru from '../pages/ru/avideo/VideoRu-Open240824';
import VideoOpen240825En from '../pages/en/avideo/VideoEn-Open240825';
import VideoOpen240825Es from '../pages/es/avideo/VideoEs-Open240825';
import VideoOpen240825Ru from '../pages/ru/avideo/VideoRu-Open240825';
import VideoOpenShipEn from '../pages/en/avideo/VideoEn-Open240826';
import VideoOpenShipEs from '../pages/es/avideo/VideoEs-Open240826';
import VideoOpenShipRu from '../pages/ru/avideo/VideoRu-Open240826';
import VideoOpen240827En from '../pages/en/avideo/VideoEn-Open240827';
import VideoOpen240827Es from '../pages/es/avideo/VideoEs-Open240827';
import VideoOpen240827Ru from '../pages/ru/avideo/VideoRu-Open240827';
import VideoOpen240828En from '../pages/en/avideo/VideoEn-Open240828';
import VideoOpen240828Es from '../pages/es/avideo/VideoEs-Open240828';
import VideoOpen240828Ru from '../pages/ru/avideo/VideoRu-Open240828';
import VideoOpen240830En from '../pages/en/avideo/VideoEn-Open240830';
import VideoOpen240830Es from '../pages/es/avideo/VideoEs-Open240830';
import VideoOpen240830Ru from '../pages/ru/avideo/VideoRu-Open240830';
import VideoOpen240831En from '../pages/en/avideo/VideoEn-Open240831';
import VideoOpen240831Es from '../pages/es/avideo/VideoEs-Open240831';
import VideoOpen240831Ru from '../pages/ru/avideo/VideoRu-Open240831';
import VideoOpen240901En from '../pages/en/avideo/VideoEn-Open240901';
import VideoOpen240901Es from '../pages/es/avideo/VideoEs-Open240901';
import VideoOpen240901Ru from '../pages/ru/avideo/VideoRu-Open240901';
import VideoOpen240903En from '../pages/en/avideo/VideoEn-Open240903';
import VideoOpen240903Es from '../pages/es/avideo/VideoEs-Open240903';
import VideoOpen240903Ru from '../pages/ru/avideo/VideoRu-Open240903';
import VideoOpen240904En from '../pages/en/avideo/VideoEn-Open240904';
import VideoOpen240904Es from '../pages/es/avideo/VideoEs-Open240904';
import VideoOpen240904Ru from '../pages/ru/avideo/VideoRu-Open240904';
import VideoOpen240905En from '../pages/en/avideo/VideoEn-Open240905';
import VideoOpen240905Es from '../pages/es/avideo/VideoEs-Open240905';
import VideoOpen240905Ru from '../pages/ru/avideo/VideoRu-Open240905';
import VideoOpen240906En from '../pages/en/avideo/VideoEn-Open240906';
import VideoOpen240906Es from '../pages/es/avideo/VideoEs-Open240906';
import VideoOpen240906Ru from '../pages/ru/avideo/VideoRu-Open240906';
import VideoOpen240907En from '../pages/en/avideo/VideoEn-Open240907';
import VideoOpen240907Es from '../pages/es/avideo/VideoEs-Open240907';
import VideoOpen240907Ru from '../pages/ru/avideo/VideoRu-Open240907';
import VideoOpen240908En from '../pages/en/avideo/VideoEn-Open240908';
import VideoOpen240908Es from '../pages/es/avideo/VideoEs-Open240908';
import VideoOpen240908Ru from '../pages/ru/avideo/VideoRu-Open240908';
import VideoOpen240910En from '../pages/en/avideo/VideoEn-Open240910';
import VideoOpen240910Es from '../pages/es/avideo/VideoEs-Open240910';
import VideoOpen240910Ru from '../pages/ru/avideo/VideoRu-Open240910';
import VideoOpen240911En from '../pages/en/avideo/VideoEn-Open240911';
import VideoOpen240911Es from '../pages/es/avideo/VideoEs-Open240911';
import VideoOpen240911Ru from '../pages/ru/avideo/VideoRu-Open240911';
import VideoOpen240912En from '../pages/en/avideo/VideoEn-Open240912';
import VideoOpen240912Es from '../pages/es/avideo/VideoEs-Open240912';
import VideoOpen240912Ru from '../pages/ru/avideo/VideoRu-Open240912';
import VideoOpen240913En from '../pages/en/avideo/VideoEn-Open240913';
import VideoOpen240913Es from '../pages/es/avideo/VideoEs-Open240913';
import VideoOpen240913Ru from '../pages/ru/avideo/VideoRu-Open240913';
import VideoOpen240914En from '../pages/en/avideo/VideoEn-Open240914';
import VideoOpen240914Es from '../pages/es/avideo/VideoEs-Open240914';
import VideoOpen240914Ru from '../pages/ru/avideo/VideoRu-Open240914';
import VideoOpen240915En from '../pages/en/avideo/VideoEn-Open240915';
import VideoOpen240915Es from '../pages/es/avideo/VideoEs-Open240915';
import VideoOpen240915Ru from '../pages/ru/avideo/VideoRu-Open240915';
import VideoOpen240916En from '../pages/en/avideo/VideoEn-Open240916';
import VideoOpen240916Es from '../pages/es/avideo/VideoEs-Open240916';
import VideoOpen240916Ru from '../pages/ru/avideo/VideoRu-Open240916';
import VideoOpen240917En from '../pages/en/avideo/VideoEn-Open240917';
import VideoOpen240917Es from '../pages/es/avideo/VideoEs-Open240917';
import VideoOpen240917Ru from '../pages/ru/avideo/VideoRu-Open240917';
import VideoOpen240918En from '../pages/en/avideo/VideoEn-Open240918';
import VideoOpen240918Es from '../pages/es/avideo/VideoEs-Open240918';
import VideoOpen240918Ru from '../pages/ru/avideo/VideoRu-Open240918';
import VideoOpen240919En from '../pages/en/avideo/VideoEn-Open240919';
import VideoOpen240919Es from '../pages/es/avideo/VideoEs-Open240919';
import VideoOpen240919Ru from '../pages/ru/avideo/VideoRu-Open240919';
import VideoOpen240921En from '../pages/en/avideo/VideoEn-Open240921';
import VideoOpen240921Es from '../pages/es/avideo/VideoEs-Open240921';
import VideoOpen240921Ru from '../pages/ru/avideo/VideoRu-Open240921';
import VideoOpen240925En from '../pages/en/avideo/VideoEn-Open240925';
import VideoOpen240925Es from '../pages/es/avideo/VideoEs-Open240925';
import VideoOpen240925Ru from '../pages/ru/avideo/VideoRu-Open240925';
import VideoOpen240926En from '../pages/en/avideo/VideoEn-Open240926';
import VideoOpen240926Es from '../pages/es/avideo/VideoEs-Open240926';
import VideoOpen240926Ru from '../pages/ru/avideo/VideoRu-Open240926';
import VideoBrillo241009En from '../pages/en/avideo/VideoEn-Brillo241009';
import VideoBrillo241009Es from '../pages/es/avideo/VideoEs-Brillo241009';
import VideoBrillo241009Ru from '../pages/ru/avideo/VideoRu-Brillo241009';
import VideoBrillo241017En from '../pages/en/avideo/VideoEn-Brillo241017';
import VideoBrillo241017Es from '../pages/es/avideo/VideoEs-Brillo241017';
import VideoBrillo241017Ru from '../pages/ru/avideo/VideoRu-Brillo241017';
import VideoBrillo241031En from '../pages/en/avideo/VideoEn-Brillo241031';
import VideoBrillo241031Es from '../pages/es/avideo/VideoEs-Brillo241031';
import VideoBrillo241031Ru from '../pages/ru/avideo/VideoRu-Brillo241031';
import VideoBrillo241114En from '../pages/en/avideo/VideoEn-Brillo241114';
import VideoBrillo241114Es from '../pages/es/avideo/VideoEs-Brillo241114';
import VideoBrillo241114Ru from '../pages/ru/avideo/VideoRu-Brillo241114';
import VideoBrillo241121En from '../pages/en/avideo/VideoEn-Brillo241121';
import VideoBrillo241121Es from '../pages/es/avideo/VideoEs-Brillo241121';
import VideoBrillo241121Ru from '../pages/ru/avideo/VideoRu-Brillo241121';
import VideoBrillo241128En from '../pages/en/avideo/VideoEn-Brillo241128';
import VideoBrillo241128Es from '../pages/es/avideo/VideoEs-Brillo241128';
import VideoBrillo241128Ru from '../pages/ru/avideo/VideoRu-Brillo241128';
import VideoBrillo241205En from '../pages/en/avideo/VideoEn-Brillo241205';
import VideoBrillo241205Es from '../pages/es/avideo/VideoEs-Brillo241205';
import VideoBrillo241205Ru from '../pages/ru/avideo/VideoRu-Brillo241205';




const Constants = {
  en: {
    Home: HomeEn,
    Menu: MenuEn,
    About: AboutEn,
    Contact: ContactEn,
    Support: SupportEn,

    EventsCulture: EventsCultureEn,
    EventsDance: EventsDanceEn,
    EventSpanish: EventSpanishEn,
    Gallery: GalleryEn,
    Photos: PhotosEn,
    Projects: ProjectsEn,
    RestaHome: RestaHomeEn,
    SchoolsDance: SchoolsDanceEn,
    SchoolsSpanish: SchoolsSpanishEn,
    SpanishLanguage: SpanishLanguageEn,
    Store: StoreEn,
    TeachersDance: TeachersDanceEn,
    TeacherSpanish: TeacherSpanishEn,
    VideoEvents: VideoEventsEn,

    LatinoDanceMoscow: LatinoDanceMoscowEn,
    OpenAirMoscow: OpenAirMoscowEn,
    LatinaFestMoscow:LatinaFestMoscowEn,

    Photo240509: Photo240509En,
    StoreAlpaca: StoreAlpacaEn,
    StorePeru: StorePeruEn,

    Salsateca: SalsatecaEn,

    VideoOpen240518: VideoOpen240518En,
    VideoOpen240519: VideoOpen240519En,
    VideoOpen240525: VideoOpen240525En,
    VideoOpen240528: VideoOpen240528En,
    VideoOpen240530: VideoOpen240530En,
    VideoOpen240531: VideoOpen240531En,
    VideoOpen240602: VideoOpen240602En,
    VideoOpen240607: VideoOpen240607En,
    VideoOpen240608: VideoOpen240608En,
    VideoOpen240609: VideoOpen240609En,
    VideoOpen240614: VideoOpen240614En,
    VideoOpen240615: VideoOpen240615En,
    VideoOpen240616: VideoOpen240616En,
    VideoOpen240619: VideoOpen240619En,
    VideoOpen240621: VideoOpen240621En,
    VideoOpen240622: VideoOpen240622En,
    VideoOpen240623: VideoOpen240623En,
    VideoOpen240625: VideoOpen240625En,
    VideoOpen240626: VideoOpen240626En,
    VideoOpen240628: VideoOpen240628En,
    VideoOpen240629: VideoOpen240629En,
    VideoOpen240630: VideoOpen240630En,
    VideoOpen240701: VideoOpen240701En,
    VideoOpen240702: VideoOpen240702En,
    VideoOpen240703: VideoOpen240703En,
    VideoOpen240706: VideoOpen240706En,
    VideoOpen240707: VideoOpen240707En,
    VideoOpen240709: VideoOpen240709En,
    VideoOpen240710: VideoOpen240710En,
    VideoOpen240712: VideoOpen240712En,
    VideoOpen240713: VideoOpen240713En,
    VideoOpen240714: VideoOpen240714En,
    VideoOpen240716: VideoOpen240716En,
    VideoOpen240717: VideoOpen240717En,
    VideoOpen240718: VideoOpen240718En,
    VideoOpen240719: VideoOpen240719En,
    VideoOpen240720: VideoOpen240720En,
    VideoOpen240723: VideoOpen240723En,
    VideoOpen240725: VideoOpen240725En,
    VideoOpen240731: VideoOpen240731En,
    VideoOpen240801: VideoOpen240801En,
    VideoOpen240802: VideoOpen240802En,
    VideoOpen240803: VideoOpen240803En,
    VideoOpen240804: VideoOpen240804En,
    VideoOpen240806: VideoOpen240806En,
    VideoOpen240807: VideoOpen240807En,
    VideoOpen240808: VideoOpen240808En,
    VideoOpen240809: VideoOpen240809En,
    VideoOpen240810: VideoOpen240810En,
    VideoOpen240813: VideoOpen240813En,
    VideoOpen240814: VideoOpen240814En,
    VideoOpen240815: VideoOpen240815En,
    VideoOpen240816: VideoOpen240816En,
    VideoOpen240820: VideoOpen240820En,
    VideoOpen240821: VideoOpen240821En,
    VideoOpen240822: VideoOpen240822En,
    VideoOpen240823: VideoOpen240823En,
    VideoOpen240824: VideoOpen240824En,
    VideoOpen240825: VideoOpen240825En,
    VideoOpenShip: VideoOpenShipEn,
    VideoOpen240827: VideoOpen240827En,
    VideoOpen240828: VideoOpen240828En,
    VideoOpen240830: VideoOpen240830En,
    VideoOpen240831: VideoOpen240831En,
    VideoOpen240901: VideoOpen240901En,
    VideoOpen240903: VideoOpen240903En,
    VideoOpen240904: VideoOpen240904En,
    VideoOpen240905: VideoOpen240905En,
    VideoOpen240906: VideoOpen240906En,
    VideoOpen240907: VideoOpen240907En,
    VideoOpen240908: VideoOpen240908En,
    VideoOpen240910: VideoOpen240910En,
    VideoOpen240911: VideoOpen240911En,
    VideoOpen240912: VideoOpen240912En,
    VideoOpen240913: VideoOpen240913En,
    VideoOpen240914: VideoOpen240914En,
    VideoOpen240915: VideoOpen240915En,
    VideoOpen240916: VideoOpen240916En,
    VideoOpen240917: VideoOpen240917En,
    VideoOpen240918: VideoOpen240918En,
    VideoOpen240919: VideoOpen240919En,
    VideoOpen240921: VideoOpen240921En,    
    VideoOpen240925: VideoOpen240925En,
    VideoOpen240926: VideoOpen240926En,
    VideoBrillo241009: VideoBrillo241009En,
    VideoBrillo241017: VideoBrillo241017En,
    VideoBrillo241031: VideoBrillo241031En,
    VideoBrillo241114: VideoBrillo241114En,
    VideoBrillo241121: VideoBrillo241121En,
    VideoBrillo241128: VideoBrillo241128En,
    VideoBrillo241205: VideoBrillo241205En,
  },

  es: {
    Home: HomeEs,
    Menu: MenuEs,
    About: AboutEs,
    Contact: ContactEs,
    Support: SupportEs,

    Prueba: PruebaEs,
    BlogDetails: BlogDetailsEs,
    Category: CategoryEs,

    EventsCulture: EventsCultureEs,
    EventsDance: EventsDanceEs,
    EventSpanish: EventSpanishEs,
    Gallery: GalleryEs,
    Photos: PhotosEs,
    Projects: ProjectsEs,
    RestaHome: RestaHomeEs,
    SpanishLanguage: SpanishLanguageEs,
    SchoolsDance: SchoolsDanceEs,
    SchoolsSpanish: SchoolsSpanishEs,
    Store: StoreEs,
    TeachersDance: TeachersDanceEs,
    TeacherSpanish: TeacherSpanishEs,
    VideoEvents: VideoEventsEs,

    TurizoLaBachata: TurizoLaBachataEs,
    LatinoDanceMoscow: LatinoDanceMoscowEs,
    TravelToSpain: TravelToSpainEs,
    NewYearLatam: NewYearLatamEs,
    OpenAirMoscow: OpenAirMoscowEs,
    LatinaFestMoscow:LatinaFestMoscowEs,

    Salsateca: SalsatecaEs,

    Photo240509: Photo240509Es,
    StoreAlpaca: StoreAlpacaEs,
    StorePeru: StorePeruEs,

    VideoOpen240518: VideoOpen240518Es,
    VideoOpen240519: VideoOpen240519Es,
    VideoOpen240525: VideoOpen240525Es,
    VideoOpen240528: VideoOpen240528Es,
    VideoOpen240530: VideoOpen240530Es,
    VideoOpen240531: VideoOpen240531Es,
    VideoOpen240602: VideoOpen240602Es,
    VideoOpen240607: VideoOpen240607Es,
    VideoOpen240608: VideoOpen240608Es,
    VideoOpen240609: VideoOpen240609Es,
    VideoOpen240614: VideoOpen240614Es,
    VideoOpen240615: VideoOpen240615Es,
    VideoOpen240616: VideoOpen240616Es,
    VideoOpen240619: VideoOpen240619Es,
    VideoOpen240621: VideoOpen240621Es,
    VideoOpen240622: VideoOpen240622Es,
    VideoOpen240623: VideoOpen240623Es,
    VideoOpen240625: VideoOpen240625Es,
    VideoOpen240626: VideoOpen240626Es,
    VideoOpen240628: VideoOpen240628Es,
    VideoOpen240629: VideoOpen240629Es,
    VideoOpen240630: VideoOpen240630Es,
    VideoOpen240701: VideoOpen240701Es,
    VideoOpen240702: VideoOpen240702Es,
    VideoOpen240703: VideoOpen240703Es,
    VideoOpen240706: VideoOpen240706Es,
    VideoOpen240707: VideoOpen240707Es,
    VideoOpen240709: VideoOpen240709Es,
    VideoOpen240710: VideoOpen240710Es,
    VideoOpen240712: VideoOpen240712Es,
    VideoOpen240713: VideoOpen240713Es,
    VideoOpen240714: VideoOpen240714Es,
    VideoOpen240716: VideoOpen240716Es,
    VideoOpen240717: VideoOpen240717Es,
    VideoOpen240718: VideoOpen240718Es,
    VideoOpen240719: VideoOpen240719Es,
    VideoOpen240720: VideoOpen240720Es,
    VideoOpen240723: VideoOpen240723Es,
    VideoOpen240725: VideoOpen240725Es,
    VideoOpen240731: VideoOpen240731Es,
    VideoOpen240801: VideoOpen240801Es,
    VideoOpen240802: VideoOpen240802Es,
    VideoOpen240803: VideoOpen240803Es,
    VideoOpen240804: VideoOpen240804Es,
    VideoOpen240806: VideoOpen240806Es,
    VideoOpen240807: VideoOpen240807Es,
    VideoOpen240808: VideoOpen240808Es,
    VideoOpen240809: VideoOpen240809Es,
    VideoOpen240810: VideoOpen240810Es,
    VideoOpen240813: VideoOpen240813Es,
    VideoOpen240814: VideoOpen240814Es,
    VideoOpen240815: VideoOpen240815Es,
    VideoOpen240816: VideoOpen240816Es,
    VideoOpen240820: VideoOpen240820Es,
    VideoOpen240821: VideoOpen240821Es,
    VideoOpen240822: VideoOpen240822Es,
    VideoOpen240823: VideoOpen240823Es,
    VideoOpen240824: VideoOpen240824Es,
    VideoOpen240825: VideoOpen240825Es,
    VideoOpenShip: VideoOpenShipEs,
    VideoOpen240827: VideoOpen240827Es,
    VideoOpen240828: VideoOpen240828Es,
    VideoOpen240830: VideoOpen240830Es,
    VideoOpen240831: VideoOpen240831Es,
    VideoOpen240901: VideoOpen240901Es,
    VideoOpen240903: VideoOpen240903Es,
    VideoOpen240904: VideoOpen240904Es,
    VideoOpen240905: VideoOpen240905Es,
    VideoOpen240906: VideoOpen240906Es,
    VideoOpen240907: VideoOpen240907Es,
    VideoOpen240908: VideoOpen240908Es,
    VideoOpen240910: VideoOpen240910Es,
    VideoOpen240911: VideoOpen240911Es,
    VideoOpen240912: VideoOpen240912Es,
    VideoOpen240913: VideoOpen240913Es,
    VideoOpen240914: VideoOpen240914Es,
    VideoOpen240915: VideoOpen240915Es,
    VideoOpen240916: VideoOpen240916Es,
    VideoOpen240917: VideoOpen240917Es,
    VideoOpen240918: VideoOpen240918Es,
    VideoOpen240919: VideoOpen240919Es,
    VideoOpen240921: VideoOpen240921Es,       
    VideoOpen240925: VideoOpen240925Es,
    VideoOpen240926: VideoOpen240926Es,
    VideoBrillo241009: VideoBrillo241009Es,
    VideoBrillo241017: VideoBrillo241017Es,
    VideoBrillo241031: VideoBrillo241031Es,
    VideoBrillo241114: VideoBrillo241114Es,
    VideoBrillo241121: VideoBrillo241121Es,
    VideoBrillo241128: VideoBrillo241128Es,
    VideoBrillo241205: VideoBrillo241205Es,
  },

  ru: {
    Home: HomeRu,
    Menu: MenuRu,
    About: AboutRu,
    Contact: ContactRu,
    Support: SupportRu,

    EventsCulture: EventsCultureRu,
    EventsDance: EventsDanceRu,
    EventSpanish: EventSpanishRu,
    Gallery: GalleryRu,
    Photos: PhotosRu,
    Projects: ProjectsRu,
    RestaHome: RestaHomeRu,
    SchoolsDance: SchoolsDanceRu,
    SchoolsSpanish: SchoolsSpanishRu,
    SpanishLanguage: SpanishLanguageRu,
    Store: StoreRu,
    TeachersDance: TeachersDanceRu,
    TeacherSpanish: TeacherSpanishRu,
    VideoEvents: VideoEventsRu,

    TurizoLaBachata: TurizoLaBachataRu,
    LatinoDanceMoscow: LatinoDanceMoscowRu,
    NewYearLatam: NewYearLatamRu,
    TravelToSpain: TravelToSpainRu,
    OpenAirMoscow: OpenAirMoscowRu,
    LatinaFestMoscow:LatinaFestMoscowRu,

    Photo240509: Photo240509Ru,
    StoreAlpaca: StoreAlpacaRu,
    StorePeru: StorePeruRu,

    Salsateca: SalsatecaRu,

    VideoOpen240518: VideoOpen240518Ru,
    VideoOpen240519: VideoOpen240519Ru,
    VideoOpen240525: VideoOpen240525Ru,
    VideoOpen240528: VideoOpen240528Ru,
    VideoOpen240530: VideoOpen240530Ru,
    VideoOpen240531: VideoOpen240531Ru,
    VideoOpen240602: VideoOpen240602Ru,
    VideoOpen240607: VideoOpen240607Ru,
    VideoOpen240608: VideoOpen240608Ru,
    VideoOpen240609: VideoOpen240609Ru,
    VideoOpen240614: VideoOpen240614Ru,
    VideoOpen240615: VideoOpen240615Ru,
    VideoOpen240616: VideoOpen240616Ru,
    VideoOpen240619: VideoOpen240619Ru,
    VideoOpen240621: VideoOpen240621Ru,
    VideoOpen240622: VideoOpen240622Ru,
    VideoOpen240623: VideoOpen240623Ru,
    VideoOpen240625: VideoOpen240625Ru,
    VideoOpen240626: VideoOpen240626Ru,
    VideoOpen240628: VideoOpen240628Ru,
    VideoOpen240629: VideoOpen240629Ru,
    VideoOpen240630: VideoOpen240630Ru,
    VideoOpen240701: VideoOpen240701Ru,
    VideoOpen240702: VideoOpen240702Ru,
    VideoOpen240703: VideoOpen240703Ru,
    VideoOpen240706: VideoOpen240706Ru,
    VideoOpen240707: VideoOpen240707Ru,
    VideoOpen240709: VideoOpen240709Ru,
    VideoOpen240710: VideoOpen240710Ru,
    VideoOpen240712: VideoOpen240712Ru,
    VideoOpen240713: VideoOpen240713Ru,
    VideoOpen240714: VideoOpen240714Ru,
    VideoOpen240716: VideoOpen240716Ru,
    VideoOpen240717: VideoOpen240717Ru,
    VideoOpen240718: VideoOpen240718Ru,
    VideoOpen240719: VideoOpen240719Ru,
    VideoOpen240720: VideoOpen240720Ru,
    VideoOpen240723: VideoOpen240723Ru,
    VideoOpen240725: VideoOpen240725Ru,
    VideoOpen240731: VideoOpen240731Ru,
    VideoOpen240801: VideoOpen240801Ru,
    VideoOpen240802: VideoOpen240802Ru,
    VideoOpen240803: VideoOpen240803Ru,
    VideoOpen240804: VideoOpen240804Ru,
    VideoOpen240806: VideoOpen240806Ru,
    VideoOpen240807: VideoOpen240807Ru,
    VideoOpen240808: VideoOpen240808Ru,
    VideoOpen240809: VideoOpen240809Ru,
    VideoOpen240810: VideoOpen240810Ru,
    VideoOpen240813: VideoOpen240813Ru,
    VideoOpen240814: VideoOpen240814Ru,
    VideoOpen240815: VideoOpen240815Ru,
    VideoOpen240816: VideoOpen240816Ru,
    VideoOpen240820: VideoOpen240820Ru,
    VideoOpen240821: VideoOpen240821Ru,
    VideoOpen240822: VideoOpen240822Ru,
    VideoOpen240823: VideoOpen240823Ru,
    VideoOpen240824: VideoOpen240824Ru,
    VideoOpen240825: VideoOpen240825Ru,
    VideoOpenShip: VideoOpenShipRu,
    VideoOpen240827: VideoOpen240827Ru,
    VideoOpen240828: VideoOpen240828Ru,
    VideoOpen240830: VideoOpen240830Ru,
    VideoOpen240831: VideoOpen240831Ru,
    VideoOpen240901: VideoOpen240901Ru,
    VideoOpen240903: VideoOpen240903Ru,
    VideoOpen240904: VideoOpen240904Ru,
    VideoOpen240905: VideoOpen240905Ru,
    VideoOpen240906: VideoOpen240906Ru,
    VideoOpen240907: VideoOpen240907Ru,
    VideoOpen240908: VideoOpen240908Ru,
    VideoOpen240910: VideoOpen240910Ru,
    VideoOpen240911: VideoOpen240911Ru,
    VideoOpen240912: VideoOpen240912Ru,
    VideoOpen240913: VideoOpen240913Ru,
    VideoOpen240914: VideoOpen240914Ru,
    VideoOpen240915: VideoOpen240915Ru,
    VideoOpen240916: VideoOpen240916Ru,
    VideoOpen240917: VideoOpen240917Ru,
    VideoOpen240918: VideoOpen240918Ru,
    VideoOpen240919: VideoOpen240919Ru,    
    VideoOpen240921: VideoOpen240921Ru,    
    VideoOpen240925: VideoOpen240925Ru,
    VideoOpen240926: VideoOpen240926Ru,
    VideoBrillo241009: VideoBrillo241009Ru,
    VideoBrillo241017: VideoBrillo241017Ru,
    VideoBrillo241031: VideoBrillo241031Ru,
    VideoBrillo241114: VideoBrillo241114Ru,
    VideoBrillo241121: VideoBrillo241121Ru,
    VideoBrillo241128: VideoBrillo241128Ru,
    VideoBrillo241205: VideoBrillo241205Ru,
  },
};

export default Constants;

